import {mapActions, mapGetters, mapMutations} from "vuex";

//sections

export default {
  name: "specification",
  props: {
    data: {
      type: Object,
      default: ()=> {}
    }
  },
  components: {},
  data() {
    return {
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
    }),
  },

  methods: {
    ...mapActions({
    }),
    ...mapMutations({
    }),
    close(){
      this.$emit('close');
    },
  }
}