import {mapActions, mapGetters, mapMutations} from "vuex";

//sections

export default {
  name: "attribute",
  props: {
    data: {
      type: Object,
      default: ()=> {}
    }
  },
  components: {},
  data() {
    return {
      // selectedAttribute: {},
    }
  },
  watch: {
    // selectedAttribute: {
    //   immediate: true,
    //   handler(newVal){
    //     if(newVal){
    //       this.changeAttributeValue()
    //     }
    //   }
    // }
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
    }),
  },

  methods: {
    ...mapActions({
    }),
    ...mapMutations({
    }),
    changeAttributeValue(data){
      console.log('data',data)
      this.$emit('change-value', {
        slug: this.data.slug,
        id: data.id
      });
    },

  }
}