import {mapActions, mapGetters, mapMutations} from "vuex";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Carousel, Slide } from 'vue-carousel';
//sections
import attributes from "@/modules/product/attributes/index.vue";
import specification from "@/modules/product/specification/index.vue";

export default {
  name: "product",
  components: {
    attributes,
    Carousel,
    Slide,
    specification
  },
  data() {
    return {
      selectedSpecification: null,
    }
  },
  watch: {
    '$route.params.slug'(newVal) {
      if (newVal) {
        this.initCart()
        this.resetProduct()
        this.fetchProduct(this.$route.params.slug).then(()=>{
          if(this.product.attributes.data.length){
            let queryAttributes = this.product.attributes.data.reduce((acc, el) => {
              acc[el.slug] = el.values.data[0].id
              return acc
            }, {})
            this.$router.push({query: Object.assign({}, this.$route.query, queryAttributes)}).catch(() => {
              console.log()
            });
          }
        }).catch(()=>{
          console.log()
        })
      }
    },
    '$route.query': {
      immediate: true,
      handler(newVal) {
        if(Object.keys(newVal).length){
          this.fetchProductImages({slug: this.$route.params.slug, payload:this.$route.query})
        }
      }
    },

  },
  created() {
    this.initCart()
    this.resetProduct()
    this.fetchProduct(this.$route.params.slug).then(()=>{
      if(this.product.attributes.data.length){
        let queryAttributes = this.product.attributes.data.reduce((acc, el) => {
          acc[el.slug] = el.values.data[0].id
          return acc
        }, {})
        this.$router.push({query: Object.assign({}, this.$route.query, queryAttributes)}).catch(() => {
          console.log()
        });
      }
    }).catch(()=>{
      console.log()
    })
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  computed: {
    ...mapGetters({
      product: 'category/product',
      productImages: 'category/productImages',
      cartLoading: 'card/cartLoading'
    }),
  },

  methods: {
    ...mapActions({
      fetchProduct: 'category/GET_PRODUCT_DATA',
      fetchProductImages: 'category/GET_PRODUCT_IMAGES',
      initCart: 'card/GET_CART',
      addOrder: 'card/ADD_ITEM_TO_CART'
    }),
    ...mapMutations({
      resetProduct: 'category/RESET_PRODUCT',
      fixBody: 'system/FIX_BODY'
    }),
    changeAttribute(attribute) {
      this.$router.push({
        query: Object.assign({},
          this.$route.query,
          {[attribute.slug]:attribute.id })}
      ).catch(() => {
        console.log()
      });
    },
    carouselNav($event, direction) {
      const carousel = this.$refs['carousel'];
      carousel.advancePage(direction);
    },
    toggleSpecification(status, data){
      if(status){
        this.fixBody(status)
        this.selectedSpecification = data
      }
      else{
        this.fixBody(status)
        this.selectedSpecification = data
      }
    },
    order(){
      this.addOrder({id: this.product.id, attribute_values: this.$route.query}).then(()=>{
         this.$router.push({name: 'order'}).catch(()=>{
           console.log()
         })
      }).catch(error => {
        this.$toasted.error(error)
      })

    },
  }
}